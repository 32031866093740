import { getNavList, getFriendLinkList } from '@/api/nav';

/**
 * 设置友情链接
 * @param {*} store
 */
export function setFriendLinkData(store) {
  return getFriendLinkList().then((res) => {
    if (res.data) {
      store.dispatch('app/setFriendLink', res.data)
    }
  })
}
/**
 * 设置栏目数据
 */
 export function setCatalogTree(store) {
  return new Promise((resolve, reject) => {
    getNavList()
      .then((res) => {
        if (res.data) {
          // var catalogList =  res.data.catalogEntityList.filter(item=> {
          //   return item.name!='路演'
          // })
          var catalogList =  res.data.catalogEntityList
          const list = catalogList.map((item) => {
            const {
              id,
              name,
              jumpUrl,
              code,
              type,
              path,
              genre,
              remarks,
              startUse,
              parentId,
              publishChannel,
              catalogEntityList
            } = item
            return {
              id,
              name,
              jumpUrl,
              code,
              type,
              path,
              genre,
              remarks,
              startUse,
              parentId,
              publishChannel,
              catalogEntityList
            }
          })
          store.dispatch('setCatalog', list)
          resolve(list)
        } else {
          reject(res)
        }
      })
  })
}
