<template>
<div class="exterpriseBox">
  <div class="bread">
    <div class="nav-box">
      <router-link to="/pages/index/index?columnId=452" class="nav-title">路演</router-link>
      <span>&gt;</span>
      <span class="nav-title" >IPO日历</span>
      <span>&gt;</span>
      <span class="nav-title">{{name}}</span>
    </div>
  </div>
  <div v-show="!isShow" class="loading-box">
    <p class="loading-iframe"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
  </div>
  <iframe 
    v-show="isShow"
    id="enterprise" 
    class="iframe-content"
    :src="`${pageDomain}/jyzd/calender/#/calender/newstock/${url}?themeType=light&token=${$store.state.accessToken}`" 
    frameborder="0" 
    scrolling="no" 
    name="announce"
    :height="domHeight"
  ></iframe>
  <SupportBrowser/>
</div>
    
</template>
<script>
import "@/assets/css/enterprise.less";
import { setCatalogTree } from "@/utils/store-data";
import SupportBrowser from "@/components/SupportBrowser.vue"
import $ from 'jquery'
export default {
    components:{ SupportBrowser },
    data() {
        return {
          time: null,
          url: '',
          name: '',
          domHeight: '',
          xgzlslLink: null,
          hash: decodeURIComponent(this.$route.params.id),
          isShow: false,
        };
    },
    computed:{
      pageDomain:function(){
        return window.location.origin;
      }
    },
    // watch: {
    //   $route(){
    //     this.hiddenLeft()
    //   }
    // },
    created() {
      setCatalogTree(this.$store);
      let url = ''
      let name = ''
      switch(this.hash) {
        case '[1]-[5]-[1]': url = 'xgrl'; name = '新股日历';  break;
        case '[1]-[5]-[2]': url = 'xgssyl'; name = '新股上市一览'; break;
        case '[1]-[5]-[3]': url = 'xgzlsl'; name = '新股资料速览'; break;
        case '[1]-[5]-[4]': url = 'yssxgzz'; name = '已上市新股追踪'; break;
        case '[1]-[5]-[5]': url = 'xgshyl'; name = '待发行公司一览'; break;
        case '[1]-[5]-[6]': url = 'xgzx'; name = '新股资讯'; break;
      }
      this.url = url
      this.name = name
      this.hiddenLeft();
    },
    beforeRouteLeave(to, from, next) {
        window.clearInterval(this.time);
        window.clearInterval(this.xgzlslLink);
        next();
    },
    methods: {
      hiddenLeft() {
        var that = this;
        this.isShow = false;
        // let hash = decodeURIComponent(this.$route.params.id)

        // let time = null
        that.time = window.setInterval(function () {
          var iframe = $('#enterprise').prop('contentWindow')
          if (iframe&&iframe.document&&iframe.document.body) {
            var iframe2 = iframe.document.body
            
            var leftMenu = iframe2.querySelector('.leftMenu')
            var rightview = iframe2.querySelector('.rightview');
            if(leftMenu&&rightview) {
              leftMenu.style.display = "none";
              iframe2.querySelector('.rightview').style.marginLeft = '0'
              that.domHeight =  rightview.clientHeight;
              
              var xgzlsl = rightview.querySelector(".xgzlsl");
              var link = rightview.querySelector(".link");
              var yssxgzz = rightview.querySelector(".yssxgzz");
              var newstockHeader = rightview.querySelector('.newstockHeader');
              if(yssxgzz && newstockHeader){
                newstockHeader.style.position = 'relative';
                newstockHeader.style.zIndex = '-20';
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
                window.clearInterval(that.time)
                that.isShow = true; 
                that.monitorPage();
              }else if(xgzlsl && link){
                link.style.display = "none";
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
                window.clearInterval(that.time)
                that.isShow = true; 
                that.monitorPage();
              }else if(rightview.querySelector('.xgssyl') || rightview.querySelector(".xgrlstock") || rightview.querySelector(".xgshyl") || rightview.querySelector(".xgzx")){
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
                if(rightview.querySelector(".xgshyl")){
                  rightview.querySelector(".xgshbody").style.width = '100%'
                }
                if(rightview.querySelector(".xgzx")){
                  rightview.querySelector(".xgzx").style.width = '100%'
                }
                window.clearInterval(that.time)
                that.isShow = true;
                that.monitorPage();
              }
            }
          }else{
            clearInterval(that.time);
          }
        }, 1000)
      },
      monitorPage(){
        var that = this;
        this.xgzlslLink = setInterval(function () {
          var iframe = $('#enterprise').prop('contentWindow');
          if (iframe&&iframe.document&&iframe.document.body) {
            var iframe2 = iframe.document.body;
            var rightview = iframe2.querySelector('.rightview');
            if(rightview){
              var xgrl = rightview.querySelector('.xgrlstock');
              var xgssyl = rightview.querySelector('.xgssyl');
              var xgzlsl = rightview.querySelector('.xgzlsl');
              var yssxgzz = rightview.querySelector(".yssxgzz");
              // 新股日历内嵌页跳转到新股资料速览后，设置面包屑得name和导航栏选中样式
              var nowNav = $(".router-link-active .gsjs_item_title");
              var silbingsNav = nowNav.parents('.gsjs_content').find(".gsjs_item");
              that.domHeight =  rightview.clientHeight;
              if(xgrl){
                that.name = '新股日历';
                for(var i in silbingsNav){
                  if(silbingsNav.eq(i).find(".gsjs_item_title").find("span").text() == '新股日历'){
                    silbingsNav.eq(i).find(".gsjs_item_title").addClass("active")
                  }else{
                    silbingsNav.eq(i).find(".gsjs_item_title").removeClass("active")
                  }
                }
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
              }else if(xgssyl){
                that.name = '新股上市一览'
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
              }else if(xgzlsl){
                that.name = '新股资料速览'
                for(var j in silbingsNav){
                  if(silbingsNav.eq(j).find(".gsjs_item_title").find("span").text() == '新股资料速览'){
                    silbingsNav.eq(j).find(".gsjs_item_title").addClass("active")
                  }else{
                    silbingsNav.eq(j).find(".gsjs_item_title").removeClass("active")
                  }
                }
                if(parseInt(that.domHeight)>=1080){
                  $('#enterprise').height(that.domHeight + 'px')
                }else{
                  $('#enterprise').height('1080px')
                }
              }else if(yssxgzz){
                that.name = '已上市新股追踪'
                // if(parseInt(that.domHeight)>=1080){
                //   $('#enterprise').height(that.domHeight + 'px')
                // }else{
                //   $('#enterprise').height('1080px')
                // }
              }
            }
          }else{
            clearInterval(that.xgzlslLink);
          }
        }, 100);
      }
    },
};
</script>
<style lang="less">
.iframe-content{
  margin: 0 auto;
  width: 1200px;
  display: block;
}
.loading-box{
  position: relative;
  min-height: calc(100vh - 240px - 350px);
  .loading-iframe{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
    color: #999999;
    >img{
      width: auto;
      height: 19px;
      display: inline-block;
    }
  }
}
</style>
