<template>
  <div v-if="supportTip" class="support-tip">
    <span class="close-support-tip" @click="closeSupport"></span>
    本功能支持谷歌内核浏览器，建议使用谷歌Chrome、360极速浏览器进行查看
  </div>
</template>
<script>
export default {
  name: "SupportBrowser",
  data() {
    return {
      supportTip: false,
    };
  },
  created() {
    // 判断浏览器类型
    var userAgent = navigator.userAgent.toLowerCase();
    if (this.isChromium(userAgent)) {
      this.supportTip = false
    } else if (this.isWebkit(userAgent)) {
      this.supportTip = false
    } else {
      this.supportTip = true
    }
  },
  methods: {
    // 关闭
    closeSupport() {
      this.supportTip = false;
    },

    // 判断是否为webkit内核浏览器
    isWebkit(userAgent) {
      if (userAgent.indexOf("applewebkit/") < 0) return false;
      return true;
    },
    // 判断是否为chrome内核浏览器
    isChromium(userAgent) {
      var chromium = "mozilla/&&applewebkit/&&chrome/&&safari/".split("&&");
      for (var i = 0; i < chromium.length; i++)
        if (userAgent.indexOf(chromium[i]) < 0) return false;
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.support-tip {
  position: fixed;
  left: 50%;
  top: 150px;
  padding: 40px 0;
  width: 1200px;
  line-height: 48px;
  border: 1px solid #ffa39e;
  text-align: center;
  font-size: 24px;
  color: #000000;
  background: #fff1f0;
  transform: translate(-50%);
  z-index: 9999;
  .close-support-tip {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url("../assets/img/side/close.png") no-repeat;
    background-size: 10px 10px;
    cursor: pointer;
  }
}
</style>